import React from 'react'
import propTypes from 'prop-types'
import { Typography, Box, RadioGroup, FormControl } from '@mui/material'
import ProvenToggle from '../../../shared/proven-toggle'
import { createProvenTheme } from '../../../../styles/theme-proven'
import { formatPrice } from '../../../../utils/helpers'
import useCurrency from '../../../../hooks/useCurrency'
import { capitalize } from 'lodash'
import { DEFAULT_SYSTEM_SUBSCRIPTION_PERIOD_IN_MONTHS } from '../../../../constants/subscriptions'
import ProvenIcon from '../../proven-icon'
import { subscriptionFrequencyToMonths } from '../../../../utils/helpers/subscription'

const themeProven = createProvenTheme()
const spacing = themeProven.spacing

function SubscribeAndSaveSelector({
  sx,
  subscriptionPrice,
  subscriptionFrequency,
  oneTimePrice,
  isSubscriptionSelected,
  onSubscriptionSelectionChange,
  isAlreadyThereAnActiveSubscription,
  renderCustomBadge,
  showPercentageInsteadOfSubsPrice = true,
  hideSubscriptionPrice = false
}) {
  const { currencyToDisplayOrEmptyIfUS, currencySymbol } = useCurrency()

  //TODO extract to a hook or helper
  const calcSubscriptionSavingPercentage = (subscriptionPrice, oneTimePrice) => {
    if (!subscriptionPrice || !oneTimePrice) {
      return 0
    }

    const percentage = (1 - subscriptionPrice / oneTimePrice) * 100

    if (percentage <= 0) {
      console.error('In calcSubscriptionSavingPercentage, percentage should be greater than 0')
      return 0
    }

    return Math.floor(percentage)
  }

  const renderLabel = ({ isSubscription, selected }) => {
    const subscriptionSavingPercentage = isSubscription
      ? calcSubscriptionSavingPercentage(subscriptionPrice, oneTimePrice)
      : 0

    const price = isSubscription
      ? `${currencySymbol}${formatPrice(subscriptionPrice)}${currencyToDisplayOrEmptyIfUS}`
      : `${currencySymbol}${formatPrice(oneTimePrice)}${currencyToDisplayOrEmptyIfUS}`

    const title = isSubscription
      ? isAlreadyThereAnActiveSubscription
        ? 'Add To Subscription'
        : subscriptionSavingPercentage
        ? `Subscribe & Save` +
          (showPercentageInsteadOfSubsPrice ? ` ${subscriptionSavingPercentage}%` : '')
        : 'Subscribe'
      : isAlreadyThereAnActiveSubscription
      ? 'Purchase One Time'
      : 'One-time Purchase'
    const frequencyInMonths = subscriptionFrequencyToMonths(subscriptionFrequency)
    const subTitle = isSubscription
      ? isAlreadyThereAnActiveSubscription
        ? 'Ships with your subscription'
        : [
            `Ships every ${frequencyInMonths} month${frequencyInMonths > 1 ? 's' : ''}`,
            'Cancel or pause anytime',
            (showPercentageInsteadOfSubsPrice ? 'Save on' : `${price} for`) + ' all future orders'
          ]
      : isAlreadyThereAnActiveSubscription
      ? 'Ships immediately'
      : ''
    const showPrice = !isSubscription || !hideSubscriptionPrice
    return (
      <Box>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          gap={1}
          sx={{ py: 0.5 }}
        >
          <Typography variant="body1" color={selected ? 'primary.main' : 'gray.blueGray'}>
            {title}
          </Typography>
          <Typography
            variant="body1"
            color={selected ? 'primary.main' : 'gray.blueGray'}
            sx={{ whiteSpace: 'nowrap', visibility: !showPrice ? 'hidden' : '', pl: 3 }}
          >
            {price}
          </Typography>
        </Box>
        {subTitle &&
          (typeof subTitle === 'string' ? (
            <Typography variant="body2" color={'gray.blueGray'}>
              {subTitle}
            </Typography>
          ) : (
            <Box>
              {subTitle.map(description => (
                <Box key={description} sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <ProvenIcon
                    type="system"
                    color="gray.blueGray"
                    name="checkmark-filled"
                    size="14px"
                  />
                  <Typography variant="body2" color="gray.blueGray">
                    {description}
                  </Typography>
                </Box>
              ))}
            </Box>
          ))}
      </Box>
    )
  }

  const renderBadge = () => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: theme => theme.spacing(5),
        px: 2,
        borderRadius: 1,
        position: 'absolute',
        top: theme => theme.spacing(-2.5),
        right: theme => theme.spacing(3)
      }}
      bgcolor="primary.main"
    >
      <Typography variant="body2" color="gray.white">
        Most Popular
      </Typography>
    </Box>
  )

  const value = isSubscriptionSelected ? 1 : 0

  const subscriptionProvenToggle = (
    <ProvenToggle
      label={renderLabel({ isSubscription: true, selected: isSubscriptionSelected })}
      name="subscription"
      value={1}
      selected={isSubscriptionSelected}
      sx={{
        mb: isAlreadyThereAnActiveSubscription ? 0 : 1,
        alignItems: 'flex-start'
        // height: { xs: spacing(14.1125), md: spacing(12.625) }
      }}
    />
  )
  const oneTimeProvenToggle = (
    <ProvenToggle
      label={renderLabel({ isSubscription: false, selected: !isSubscriptionSelected })}
      name="one-time"
      value={0}
      sx={{
        mb: isAlreadyThereAnActiveSubscription ? 1 : 0,
        alignItems: 'flex-start'
        // height: { xs: spacing(14.1125), md: spacing(12.625) }
      }}
      selected={!isSubscriptionSelected}
    />
  )

  return (
    <Box sx={sx}>
      <FormControl sx={{ width: 1 }}>
        <RadioGroup
          aria-labelledby="skip-the-line-select"
          name="skip-the-line-select"
          value={value}
          onChange={onSubscriptionSelectionChange}
        >
          {isAlreadyThereAnActiveSubscription ? (
            <>
              {oneTimeProvenToggle}
              {subscriptionProvenToggle}
            </>
          ) : (
            <>
              {renderCustomBadge ? renderCustomBadge() : renderBadge()}
              {subscriptionProvenToggle}
              {oneTimeProvenToggle}
            </>
          )}
        </RadioGroup>
      </FormControl>
    </Box>
  )
}

SubscribeAndSaveSelector.propTypes = {
  sx: propTypes.object,
  subscriptionPrice: propTypes.number,
  oneTimePrice: propTypes.number,
  isSubscriptionSelected: propTypes.number,
  onSubscriptionSelectionChange: propTypes.func,
  renderCustomBadge: propTypes.func
}

export default SubscribeAndSaveSelector
