import React, { useEffect, useState } from 'react'
import useAuth from 'hooks/use-auth'
import { useDispatch, useSelector } from 'react-redux'
import { pathOr } from 'ramda'
import ProductDetails from './product-details'
import { updateAccountInfo } from '../../../actions/account.actions'
import ProvenButtonWithIntent from '../proven-button-with-intent'
import { COMBO_PRODUCTS, ONE_TIME_PRODUCT_IDS } from '../../../constants/products'
import { ThemeProvider } from '@mui/material/styles'
import { createProvenTheme } from '../../../styles/theme-proven'
import FullIngredientsListDrawer from './full-ingredients-list-drawer'
import { isEmpty } from 'lodash'
import SubscribeAndSaveSelector from './subscribe-and-save-selector'
import { getDefaultProductFrequency } from '../../../utils/helpers/product'
import { getSubscriptionPrice } from '../../../utils/helpers/subscription'
import {
  activeSubscriptionsSelect,
  isNextActionOnCurrentProductPageOneTimeSelector,
  productsSelector
} from '../../../utils/selectors'
import { lookForActiveSubsWhereIndividualProductCanBeAdded } from '../../../utils/helpers'
import {
  setOneTimeOptionForNextActionOnCurrentProductPage,
  setSubscriptionOptionForNextActionOnCurrentProductPage
} from '../../../actions/app.actions'
import ProvenButtonAddToCart from '../proven-button-add-to-cart'
import IngredientsListDrawer from '../ingredients-list-drawer'

const themeProven = createProvenTheme()

function LPProductHeroBasedOnUserAuthentication({
  product: productId,
  description: descriptionForBuilder,
  size: sizeForNonAuthenticatedUser,
  title: titleForNonAuthenticatedUser,
  subtitle: subtitleForNonAuthenticatedUser,
  features,
  carouselImages,
  horizontallyReversed,
  hideTargetingSection,
  hideIngredientsSection
}) {
  const activeSubscriptions = useSelector(activeSubscriptionsSelect)
  const isNextActionOnCurrentProductPageOneTime = useSelector(
    isNextActionOnCurrentProductPageOneTimeSelector
  )
  const [isSubscriptionSelected, setSubscriptionSelected] = useState(
    +!isNextActionOnCurrentProductPageOneTime
  )
  const productsPrices = useSelector(state => productsSelector(state))
  const dispatch = useDispatch()

  useEffect(() => {
    if (COMBO_PRODUCTS.includes(productId)) {
      if (isNextActionOnCurrentProductPageOneTime) {
        dispatch(setSubscriptionOptionForNextActionOnCurrentProductPage())
        setSubscriptionSelected(1)
      }
    } else {
      if (!isNextActionOnCurrentProductPageOneTime) {
        dispatch(setOneTimeOptionForNextActionOnCurrentProductPage())
        setSubscriptionSelected(0)
      }
    }
  }, [])

  const handleOnSubscriptionSelectionChange = e => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    if (e.target.value && !isSubscriptionSelected) {
      setSubscriptionSelected(1)
      dispatch(updateAccountInfo({ isSubscriptionSelected: true }))
      dispatch(setSubscriptionOptionForNextActionOnCurrentProductPage())
    } else {
      setSubscriptionSelected(0)
      dispatch(updateAccountInfo({ isSubscriptionSelected: false }))
      dispatch(setOneTimeOptionForNextActionOnCurrentProductPage())
    }
  }

  const { isAuthenticated } = useAuth()
  const { skin, lifestyle, water_hardness, air_quality, climate, products } = useSelector(state =>
    pathOr({}, ['account', 'results'], state)
  )

  let elementsWithProductsTags = []
  if (climate?.length && !isEmpty(climate[0])) elementsWithProductsTags.push(climate[0])
  if (air_quality?.length && !isEmpty(air_quality[0])) elementsWithProductsTags.push(air_quality[0])
  if (water_hardness?.length && !isEmpty(water_hardness[0]))
    elementsWithProductsTags.push(water_hardness[0])

  if (skin?.length)
    elementsWithProductsTags = elementsWithProductsTags.concat(skin.filter(s => !isEmpty(s)))
  if (lifestyle?.length)
    elementsWithProductsTags = elementsWithProductsTags.concat(lifestyle.filter(l => !isEmpty(l)))

  const tags = elementsWithProductsTags.reduce((tagsAccum, elementWithTag) => {
    if (elementWithTag?.tags_on_products) tagsAccum.push(elementWithTag.tags_on_products)
    return tagsAccum
  }, [])

  const getProductFromResults = () => {
    if (!isAuthenticated) return undefined
    const product = products.find(p => p.productPlanGroup === productId)
    if (!product) return undefined
    return product
  }

  let description,
    size,
    title,
    subtitle,
    carouselImagesUrls,
    ingredients = []
  const product = getProductFromResults()
  if (product) {
    description = product.description
    size = product.size
    title = product.title
    subtitle = product.subtitle
    carouselImagesUrls = product?.shop_image_gallery || []
    ingredients = product.ingredients
  } else {
    description = descriptionForBuilder
    size = sizeForNonAuthenticatedUser
    title = titleForNonAuthenticatedUser
    subtitle = subtitleForNonAuthenticatedUser
    carouselImagesUrls = (carouselImages || []).map(i => i.image)
  }

  const isThereActiveSubsWhereIndividualProductCanBeAdded =
    !COMBO_PRODUCTS.includes(productId) &&
    lookForActiveSubsWhereIndividualProductCanBeAdded(productId, activeSubscriptions)

  if (isAuthenticated === null) return null

  const productPrices = productsPrices.find(p => p.id === productId)
  const frequencyAndUnit = getDefaultProductFrequency(productPrices)
  const frequency = frequencyAndUnit.frequency
  const subscriptionPrice =
    getSubscriptionPrice(productPrices, frequency)?.price || productPrices.one_time_price.price
  const oneTimePrice = productPrices.one_time_price.price

  const actionButton = isAuthenticated ? (
    <ProvenButtonAddToCart
      color="secondary"
      variant="contained"
      forceSubscriptionAction={false}
      textToShowForAuthenticated="GET MY FORMULA"
      textToShowForUnauthenticated="GET MY FORMULA"
      textToShowForAddingToSubscription="CONFIRM TO ADD"
      addPriceWhenIsAuthenticated={false}
      productId={productId}
      addSavingCancellingAndShippingLegend={false}
      fullWidth={false}
    />
  ) : (
    <ProvenButtonWithIntent text="GET MY FORMULA" variant="contained" color="secondary" />
  )

  return (
    <ThemeProvider theme={themeProven}>
      <ProductDetails
        horizontallyReversed={horizontallyReversed}
        size={size}
        title={title}
        tags={tags}
        subtitle={subtitle}
        features={features || []}
        description={description}
        carouselImagesUrls={carouselImagesUrls || []}
        subscribeAndSaveSelector={
          isAuthenticated ? (
            <SubscribeAndSaveSelector
              productId={productId}
              subscriptionPrice={subscriptionPrice}
              oneTimePrice={oneTimePrice}
              subscriptionFrequency={frequencyAndUnit}
              isSubscriptionSelected={isSubscriptionSelected}
              isAlreadyThereAnActiveSubscription={isThereActiveSubsWhereIndividualProductCanBeAdded}
              onSubscriptionSelectionChange={handleOnSubscriptionSelectionChange}
            />
          ) : undefined
        }
        actionButton={actionButton}
        ingredients={ingredients}
        isOneTimeAddOnsProduct={ONE_TIME_PRODUCT_IDS.includes(productId)}
        hideTargetingSection={hideTargetingSection}
        hideIngredientsSection={hideIngredientsSection}
      />
    </ThemeProvider>
  )
}

export default LPProductHeroBasedOnUserAuthentication
